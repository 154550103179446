.styleDefaultBtn{
    background: transparent;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 0.2rem;
    color: #757575;
    font-weight: 500;
}

.styleFlexibleDropdown .MuiSelect-select.MuiSelect-select{
    padding: 2px;
    font-size: 0.8rem;
}
.styleFlexibleDropdown .MuiSelect-select:focus{
    background-color: #ffffff;
}
.styleFlexibleDropdownDisabled .MuiSelect-select{
    background-color: #b2b2b212 !important;
}