.styleVAlueHelpClose .MuiTypography-root {
    display: flex;
    font-weight: none;
    color: white;
}
.styleVAlueHelpClose.MuiDialogTitle-root{
    padding: 0rem 0rem 0rem 0.8rem;
    background-color: #3f51b5;
}
.styleVAlueHelpClose .MuiSvgIcon-root{
   color:whhite 
}