.styleButtonGroup.MuiButtonGroup-root{
    height:1.8rem;
    
}
.styleButtonGroup .MuiButtonGroup-grouped{
    min-width: 2rem;
}
.styleActiveButton.MuiIconButton-root{
    border-radius: 4px;
    background: #007AD4;
    color:#FFFFFF;
    padding: 3px 4px 3px 4px;
}
.styleActiveButton.MuiButtonBase-root:hover{
   
    background: #007AD4;
    color:#FFFFFF;
    padding:3px 4px 3px 4px;
}
.styleDefaultButton.MuiIconButton-root{
    border-radius: 4px;
    background: #ffffff;
    color:#757575;
    padding: 3px 4px 3px 4px;
}
.styleDefaultButton.MuiButtonBase-root:hover{
   
    background: #ffffff;
    color:#757575;
    padding: 3px 4px 3px 4px;
}
.styletable .MuiTableCell-root {
    padding: 0px 0.5rem !important;
    color: #212121;
    /* border-bottom:none */
}
.styletable .MuiTableBody-root{
height: 1rem !important;
}

.styleEyeIcon.MuiIconButton-root{
    background:transparent;
    color:#007AD4;
    font-size: 1rem
}
.styleEyeIcon.MuiIconButton-colorPrimary:hover{
    background: transparent;
    color:#757575;
}
.styleDefaultEyeIcon.MuiIconButton-root{
    background:transparent;
    color:#757575;
    /* padding: 10px 18px; */
    height: 15.34px;
    width: 15.34px;
    padding: 16px 10px;
    margin-right: 20px;
    font-size: 1rem;
}
.styleDefaultEyeIcon.MuiIconButton-colorPrimary:hover{
    background: transparent;
    color:#757575
}

.styleEditIcon.MuiIconButton-root{
    background:transparent;
    color: #0D0AB5;
}
.styleEditIcon.MuiIconButton-colorPrimary:hover{
    background: transparent;
    color:#0D0AB5;
}
.styleCard{
    border: 0.5px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 0.4rem 0 0.4rem;
    margin: 0.6rem;
    width:13.4rem;
    height: 8rem;
}
.styleButton.MuiIconButton-root{
    padding:0rem 0.2rem 0rem 0.2rem !important;
    text-transform: none !important;  
}
.stylePopover .MuiPopover-paper{
    box-shadow: none;
}
.styleSelection.MuiButtonBase-root{
    color:'#007AD4' !important
}