/* .App{
    display: flex;
    align-items: center;
   align-content: center;

} */
.header {
    position: fixed;
    top: 0;
    width: 100%
}

.styleUserProfile.MuiIconButton-root:hover {
    background-color: transparent;
}

.logo-line {
    border: 1px solid #757575;
    height: 1.5rem;
    margin: 0 0.5rem;
}

.logo-text {
    color: #1D1D1D;
    font-size: 1.125rem
}

.styleCell {
    border: 1px solid #EEEEEE;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;

}

.min-width {

    min-width: 7rem;
}
.header-cell.MuiTableCell-root{
    text-align: center;
}
.header-cell-bold.MuiTableCell-root{
    font-weight: 600;
}