@import url(./utility/customTheme.css);

.styleIcon.MuiIconButton-root {
    /* padding-top: 20px; */
    /* padding-left: 12px */
}

.styleIcon.MuiSvgIcon-root {
    height: 1.2rem;
    width: 1.2rem;
}

.styleVerticalFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.styleIconBackground {
    color: #007AD4;
    background-color: #EDF7FF;
    border-radius: 0.5rem;
}