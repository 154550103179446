.styletreeItem .MuiTreeItem-content{
    margin: 6px 2px 2px 2px;
    padding-left: 1px !important;    
    border-radius: 4px !important;
}


.styletreeItem{
    margin-right: 0.5rem !important;
}
.styletreeItem .MuiTreeItem-label{
    font-size: 0.85rem;
    padding-left: 0 !important;
}

.styletreeItem .MuiTreeItem-iconContainer{
    width: 1rem !important;
}
.styletreeItem .MuiTreeItem-iconContainer:focus{
    width: 1 !important;
}
.styletreeItem .makeStyles-content{
    border-radius: 2px !important;
    padding-left:1 !important;
    padding-right: 2.5rem  !important;
}
.styletreeItem .MuiSvgIcon-root{
    width:1rem !important;
    height: 1rem !important;
}
.styleAccSummary.MuiAccordionSummary-root{
    padding: 0;
    height: 100%;
    /* padding-left: 1rem; */
    min-height: 0;
}
.styleAccSummary{
    height: 2rem;
}
.styleAccSummary.MuiAccordionSummary-root.Mui-expanded {
    min-height: 1rem;
}
.styleAccSummary .MuiAccordionSummary-content.Mui-expanded{
    margin:0
}
.styleAccordion.MuiAccordion-root {border: 1px solid #ccc;
margin: 0.3rem;
border-radius: 5px;}
.styleAccordion .MuiAccordionDetails-root{
    padding: 0;
}
.styleAccordion.MuiAccordion-root{
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.styleAccSummary .MuiAccordionSummary-content{
margin:0
}
.styleListIcon .MuiSvgIcon-root{
    height: 1rem;
    width: 1rem;
}
