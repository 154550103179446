@import url(./../utility/theme.css);
.ValidateToolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.styleFlex {
    display: flex;
    width: 100%;
    height: 100%;
}

.styleFlex1 {
    width: 23rem
}

.StyleMenu {
    padding: 0 0 0 0;

}

.styleButton.MuiButton-root {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    text-transform: none !important;

}

.styleButton.MuiButton-label {
    font-size: 0.85rem;
}

.styleButton .MuiButton-iconSizeMedium>*:first-child {
    font-size: 18px;
}



.styleLink {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 14px !important;
    display: flex;
    align-items: center;
    letter-spacing: 0.004em !important;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.styleSearch {
    width: 12.375rem;
}

.styleSearch .MuiOutlinedInput-root {
    background-color: '#ffffff' !important;
}

.styleSearch .MuiOutlinedInput-input {
    padding: 0.5rem 0.5rem;
    background-color: var(--primary-color);
}

.styleSearch .MuiInputBase-input {
    height: 1rem;
}

.styleSearch .MuiIconButton-root {
    padding: 0;
}

.styleLineHeight.MuiButton-root {
    line-height: 0;
}

.footer {
    position: 'fixed';

    left: '0';

    bottom: '0';

    z-index: 10;

    padding: '0.5rem';

    width: '100%';

    display: 'inline-flex';

    justify-content: 'flex-end';

    background-color: "red"
}

.styleRoundPadding.MuiIconButton-root {
    padding: 0.3rem !important;
}