/* 
//  As per UX needs, icons are switched when hovered.
// Without hover - it is an outlined icon
// On hover - the icon is filled. */

.cwOutlineIcon {
    display: flex;
}
.cwFilledIcon{
    display:none;
}
.cwIconContainer{
    align-items: center;
}
.cwIconContainer:hover .cwOutlineIcon {
    display:none;
}
.cwIconContainer:hover .cwFilledIcon {
    display:flex;
}