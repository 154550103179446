.customAppBar.MuiToolbar-gutters {
    padding-bottom: 0rem;
}

.customAppBar.MuiToolbar-regular {
    min-height: 0;
}

.styleNavIcon {
    min-width: 20px;
}

.styleListItem.MuiListItem-root {
    padding: 0 0.75rem;


}

.styleNavIcon .MuiListItemIcon-root {
    min-width: none !important;
}

.styleListItem .MuiTypography-body1 {
    font-size: 0.875rem;
}