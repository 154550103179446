.comment::-webkit-scrollbar {
  display: none;
}

.styleCommentBox .MuiOutlinedInput-input {
  padding: 0.3rem;
}

.styleCommentBox .MuiOutlinedInput-root {
  padding-right: 0;
}

.styleCommentBox .MuiInputBase-input {
  height: 1rem;

  font-size: 0.875rem;

}