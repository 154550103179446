:root {
    --primary-main-color: #007AD4;
    --primary-light-color: #42a5ec;
    --primary-dark-color: #035796;
    --primary-contrastText-color: #ffff;

    --secondary-main-color: #E3F2FF;
    --secondary-light-color: #F4F9FF;
    --secondary-dark-color: #9ca6af;
    --secondary-contrastText-color: #000000;

    --text-primary-color: #1D1D11;
    --text-secondary-color: #757575;
    --text-hint-color: rgba(0, 0, 0, 0.5);
    --text-disabled-color: #625e5e;

    --background-default-color: #FFFFFF;
    --background-paper-color: #FFFFFF;
    --background-dark-color: #FAFAFA;
    --background-disabled-color: #E0E0E0;

    --error-main-color: #fb4235;
    --error-light-color: #f15c53;
    --error-dark-color: #aa2c24;

    --warning-main-color: #ff8c06;
    --warning-light-color: #ff9443;
    --warning-dark-color: #c96f06;

    --info-main-color: #229af9;
    --info-light-color: #56b0f9;
    --info-dark-color: #166db3;

    --success-main-color: #2af532;
    --success-light-color: #46f54e;
    --success-dark-color: #0f9a15;

    --icon-secondary: #007AD4;
    --icon-tertiary: #007AD4;

}

.h1 {
    font-weight: 550;
    font-size: 1.2rem;
}

.h2 {
    font-weight: 500;
    font-size: 1rem;
}

.h3 {
    font-size: 0.9rem;
    font-weight: 500;
}

.h4 {
    font-size: 0.8rem;
    font-weight: 500;
}

.h5 {
    font-size: 0.7rem;
    font-weight: 400;
}

.h6 {
    font-size: 0.7rem;
    font-weight: 400;
}

.padding-small {
    padding: 0.5rem;
}

.padding-medium {
    padding: 1rem;
}

.margin-small {
    margin: 0.5rem;
}

.margin-medium {
    margin: 1rem;
}

.font-size-small {
    font-size: 0.5rem;
}

.font-size-medium {
    font-size: 0.85rem;
}

.font-size-large {
    font-size: 1rem;
}