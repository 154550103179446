.MuiTab-wrapper{
    display: contents !important;
  
}
.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin-bottom: 0rem!important;
}
.MuiTab-labelIcon{
    min-height: 3rem !important;
}
.MuiTabs-root{
    margin-left: 1rem;
}
.viewDtTab.MuiTabPanel-root{
    padding: 0.5rem!important;
    background-color: #FAFCFF !important;
    height: 100% !important;
   
}
.container{
    width: "98%";
    margin-top: "0.5rem";
    border-radius: "0.5rem 0.5rem 0.5rem 0.5rem";
}
.tableCell{
    padding: 0px 6px !important;
    height:2rem;
}
.tableRow{
    height: 30;
   
}
.tableRow:hover{

        background-color: #E5E5E5 !important
    
}