.styleDefaultTab.MuiButton-root{
    text-transform: none !important;
    color:#212121
}
.styleActiveTab.MuiButton-root{
    text-transform: none !important;
    border-bottom: 2px solid #007AD4;
    border-radius: 0;
    /* font-family: 'Roboto'; */
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;
    color: #007AD4;

}
.styleBusyLoader.MuiBackdrop-root{
  z-index: 99999 !important;
  background-color: transparent;
}
/* .styleActiveTab .MuiButtonBase-root{
border-radius: 0;
} */
.StyleTab.MuiButton-textPrimary:hover{
  background-color: transparent !important;
}
.StyleTab.MuiButton-root:hover{
  background-color: transparent !important;
}
.StyleTab.MuiButtonBase-root-MuiButton-root{
  padding: 5px 4px 5px 0;
}
.StyleTab.MuiButton-root{
  padding: 0 0 4px 0;

}